.facets-container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(2, auto);
  padding-top: 0;
}

.active-facets-mobile {
  margin-bottom: 0.5rem;
}

.mobile-facets__list {
  overflow-y: auto;
}

@media screen and (min-width: 750px) {
  .facets-container > * + * {
    margin-top: 0;
  }

  .facets__form .product-count {
    grid-column-start: 3;
    align-self: flex-start;
  }
}

@media screen and (max-width: 989px) {
  .facets-container {
    grid-template-columns: auto minmax(0, max-content);
    column-gap: 2rem;
  }
}

.facet-filters {
  align-items: flex-start;
  display: flex;
  grid-column: 2;
  grid-row: 1;
  padding-left: 2.5rem;
}

@media screen and (min-width: 990px) {
  .facet-filters {
    padding-left: 3rem;
  }
}

.facet-filters__label {
  display: block;
  color: rgba(var(--color-foreground), 0.85);
  font-size: 1.4rem;
  margin: 0 2rem 0 0;
}

.facet-filters__summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  cursor: pointer;
  height: 4.5rem;
  padding: 0 1.5rem;
  min-width: 25rem;
  margin-top: 2.4rem;
  border: 0.1rem solid rgba(var(--color-foreground), 0.55);
}

.facet-filters__summary::after {
  position: static;
}

.facet-filters__field {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.facet-filters__field .select {
  width: auto;
}

.facet-filters__field .select:after,
.facet-filters__field .select:before,
.mobile-facets__sort .select:after,
.mobile-facets__sort .select:before {
  content: none;
}

.facet-filters__field .select__select,
.mobile-facets__sort .select__select {
  border-radius: 0;
  min-width: auto;
  min-height: auto;
  transition: none;
}

.facet-filters button {
  margin-left: 2.5rem;
}

.facet-filters__sort {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  font-size: 1.4rem;
  height: auto;
  line-height: calc(1 + 0.5 / var(--font-body-scale));
  margin: 0;
  padding-left: 0;
  padding-right: 1.75rem;
}

.facet-filters__sort + .icon-caret {
  right: 0;
}

@media screen and (forced-colors: active) {
  .facet-filters__sort {
    border: none;
  }
}

.facet-filters__sort,
.facet-filters__sort:hover {
  box-shadow: none;
  filter: none;
  transition: none;
}

.mobile-facets__sort .select__select:focus-visible {
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
  outline-offset: 0.3rem;
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)), 0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3);
}

.mobile-facets__sort .select__select.focused {
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
  outline-offset: 0.3rem;
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)), 0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3);
}

.facet-filters__sort:focus-visible {
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
  outline-offset: 1rem;
  box-shadow: 0 0 0 1rem rgb(var(--color-background)), 0 0 0.2rem 1.2rem rgba(var(--color-foreground), 0.3);
}

.facet-filters__sort.focused {
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
  outline-offset: 1rem;
  box-shadow: 0 0 0 1rem rgb(var(--color-background)), 0 0 0.2rem 1.2rem rgba(var(--color-foreground), 0.3);
}

.facets {
  display: block;
  grid-column-start: span 2;
  margin-bottom: 2rem;
}

.facets__form {
  display: grid;
  gap: 0 3.5rem;
  grid-template-columns: 1fr max-content max-content;
  margin-bottom: 0.5rem;
}

.facets__wrapper {
  align-items: center;
  align-self: flex-start;
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-wrap: wrap;
}

.facets__heading {
  display: block;
  color: rgba(var(--color-foreground), 0.85);
  font-size: 1.4rem;
  margin: -1.5rem 2rem 0 0;
}

.facets__reset {
  margin-left: auto;
}

.facets__disclosure {
  margin-right: 3.5rem;
}

.facets__summary {
  color: rgba(var(--color-foreground), 0.75);
  font-size: 1.4rem;
  padding: 0 1.75rem 0 0;
  margin-bottom: 1.5rem;
}

.facets-vertical .facets__summary {
  padding-top: 1.2rem;
  margin-bottom: 0;
  padding-bottom: 1.2rem;
}

.facets__disclosure fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

.facets__disclosure[open] .facets__summary,
.facets__summary:hover {
  color: rgb(var(--color-foreground));
}

.facets__disclosure[open] .facets__display,
.facets__disclosure-vertical[open] .facets__display-vertical {
  animation: animateMenuOpen var(--duration-default) ease;
}

.facets__summary span {
  transition: text-decoration var(--duration-short) ease;
}

.facets__and-helptext {
  color: rgba(var(--color-foreground), 0.5);
  font-size: calc(var(--font-heading-scale) * 1.2rem);
  line-height: calc(var(--font-heading-scale) * 1.2rem);
}

@media only screen and (min-width: 750px) {
  .facets__and-helptext {
    font-size: calc(var(--font-heading-scale) * 1.3rem);
    line-height: calc(var(--font-heading-scale) * 1.3rem);
  }
}

.facets__disclosure .facets__and-helptext,
.facets__disclosure-vertical .facets__and-helptext {
  display: none;
}

.facets__disclosure[open] .facets__and-helptext,
.facets__disclosure-vertical[open] .facets__and-helptext {
  display: block;
}

.disclosure-has-popup[open] > .facets__summary::before {
  z-index: 2;
}

.facets__summary > span {
  line-height: calc(1 + 0.3 / var(--font-body-scale));
}

.facets__summary .icon-caret {
  right: 0;
}

.facets__display {
  border-width: var(--popup-border-width);
  border-style: solid;
  border-color: rgba(var(--color-foreground), var(--popup-border-opacity));
  border-radius: var(--popup-corner-radius);
  box-shadow: var(--popup-shadow-horizontal-offset) var(--popup-shadow-vertical-offset) var(--popup-shadow-blur-radius)
    rgba(var(--color-shadow), var(--popup-shadow-opacity));
  background-color: rgb(var(--color-background));
  position: absolute;
  top: calc(100% + 0.5rem);
  left: -1.2rem;
  width: 35rem;
  max-height: 55rem;
  overflow-y: auto;
}

.facets__header {
  border-bottom: 1px solid rgba(var(--color-foreground), 0.2);
  padding: 1.5rem 2rem;
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  position: sticky;
  top: 0;
  background-color: rgb(var(--color-background));
  z-index: 1;
}

.facets__header facet-remove {
  align-self: center;
}

.facets__list {
  padding: 0.5rem 2rem;
}

.facets-layout-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  text-align: center;
  padding: 2rem 2.4rem;
}

.facets-layout-list--swatch {
  --swatch-input--size: 2.4rem;
}

.facets-layout-grid.facets-layout-grid {
  gap: 1rem;
}

.facets-layout-grid.facets__list--vertical {
  padding: 1rem 0;
}

.facets__item {
  display: flex;
  align-items: center;
  margin-bottom: 1.4rem;
}

/* Hover/focus state */
.facets-layout-list .facets__label:hover .facet-checkbox__text-label,
.facets-layout-list input:focus ~ .facet-checkbox__text-label {
  text-decoration: underline;
}

.facets-layout-grid > * {
  align-items: flex-start;
}

.facets-layout-grid .facets__label {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100%;
  font-size: 1.3rem;
}

/* Image filter specific styles */
.facets-layout-grid .facets__image-wrapper {
  display: block;
  position: relative;
  aspect-ratio: 1 / 1;
  min-height: 0;
  padding: 0.4rem;
}

.facets__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Default state */
.facets-layout-grid--image .facets__label {
  outline-style: solid;
  outline-color: transparent;
  transition-property: outline-color, outline-width, box-shadow;
  transition-duration: var(--duration-short);
  transition-timing-function: ease;
}

.facets-layout-grid--image .facet-checkbox__text {
  padding: 0.4rem;
}

/* Active state */
.facets-layout-grid--image .facets__label.active {
  outline-color: rgb(var(--color-foreground));
  outline-width: 0.1rem;
}

/* Hover state */
.facets-layout-grid--image .facets__label:hover {
  outline-color: rgba(var(--color-foreground), 0.4);
  outline-width: 0.2rem;
}

/* Focus visible */
.facets-layout-grid--image .facets__label:has(:focus-visible) {
  outline-color: rgba(var(--color-foreground), 0.5);
  outline-width: 0.2rem;
  box-shadow: 0px 0px 3px 1px rgba(var(--color-foreground), 0.25);
}

/* Focused state */
.facets-layout-grid--image .facets__label.active:has(:focus-visible) {
  outline-color: rgb(var(--color-foreground));
  outline-width: 0.1rem;
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)), 0 0 0 0.5rem rgba(var(--color-foreground), 0.5),
    0 0 0.7rem 0.1rem rgba(var(--color-foreground), 0.25);
}

/* Disabled state */
.facets-layout-grid--image .facets__label.disabled {
  /* Note: disabled gets its transparency set by a parent, so no need to set an alpha here. Also, we move the outline to the child when disabled */
  outline: none;
}

.facets-layout-grid--image .facets__label.disabled .facets__image-wrapper {
  outline: 0.1rem solid rgb(var(--color-foreground));
}

.facets-layout-grid--image .facets__label.disabled .facets__image-wrapper:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  /* 100% * square root of 2, this is not a magic number, it is math! */
  width: 141.4%;
  height: 0.1rem;
  background-color: rgb(var(--color-foreground));
  transform: rotate(-45deg);
  transform-origin: left;
}

.facets-layout-grid--image .facets__label.disabled .disabled-line {
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  background: transparent;
  margin: 0;
}

.facets-layout-grid--image .facets__label.disabled .disabled-line line {
  stroke: rgb(var(--color-foreground));
  stroke-width: 1;
}

/* End of image filter specific styles */

.list-menu__item label,
.list-menu__item input[type='checkbox'] {
  cursor: pointer;
}

.facet-checkbox {
  font-family: var(--font-family);
  padding: 0 0 0 2.2rem;
  flex-grow: 1;
  position: relative;
  font-size: 1.4rem;
  line-height: 1.96rem;
  font-weight: 400;
  display: flex;
  word-break: break-word;
  color: rgb(var(--color-foreground));
  transition: ease-in-out 0.3s;
}

/* Hover, active, and focus states */
.facets__label.active,
.facets__label:has(:focus-visible) {
  color: rgba(var(--color-foreground), 1);
}

.facets-layout-list .facet-checkbox.facets__label:hover .facet-checkbox__text-label {
  text-decoration: none;
}

/* Disabled state */
.facets-layout .facets__label.disabled {
  pointer-events: none;
}

.facets-layout:not(.facets-layout-list--swatch, .facets-layout-grid--image) .facets__label.disabled {
  opacity: 0.4;
}

.facets-layout-grid--image .facets__label.disabled .facets__image-wrapper {
  opacity: 0.2;
}

:is(.facets-layout-list--swatch, .facets-layout-grid--image) .facets__label.disabled .facet-checkbox__text {
  opacity: 0.4;
}

/* End disabled state */

.facets-layout-list--text input[type='checkbox'] {
  position: absolute;
  opacity: 1;
  width: 1.4rem;
  height: 1.4rem;
  top: 0.2rem;
  left: 0;
  appearance: none;
  -webkit-appearance: none;
  border-radius: 0.3rem;
  border : 0.1rem solid rgba(var(--color-shadow),1);
  margin: 0;
}

.facets-layout-grid input[type='checkbox'],
.facets-layout-list--swatch input[type='checkbox'] {
  position: absolute;
  inset: 0;
  z-index: 1;
  margin: 0;
  opacity: 0;
}

.facets-layout-list--swatch .facets__label {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.swatch-input-wrapper {
  display: flex;
}

.facet-checkbox > svg {
  background-color: rgb(var(--color-background));
  margin-right: 1.2rem;
  flex-shrink: 0;
}

.facet-checkbox .icon-checkmark {
  visibility: hidden;
  position: absolute;
  left: 0.3rem;
  z-index: 5;
  top: 1.4rem;
}

.facet-checkbox > input[type='checkbox']:checked ~ .icon-checkmark {
  visibility: visible;
}

@media screen and (forced-colors: active) {
  .facet-checkbox > svg {
    background-color: inherit;
    border: 0.1rem solid rgb(var(--color-background));
  }

  .facet-checkbox > input[type='checkbox']:checked ~ .icon-checkmark {
    border: none;
  }
}

.facets__price {
  display: flex;
  padding: 2rem;
}

.facets__price .field + .field-currency {
  margin-left: 2rem;
}

.facets__price .field {
  align-items: center;
}

.facets__price .field-currency {
  align-self: center;
  margin-right: 0.6rem;
}

.facets__price .field__label {
  left: 1.5rem;
}

button.facets__button {
  min-height: 0;
  margin: 0 0 0 0.5rem;
  box-shadow: none;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
}

.active-facets {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  grid-column: 1 / -1;
  grid-row: 2;
  margin-top: -0.5rem;
}

.facets-vertical .active-facets {
  margin: 0;
  align-items: flex-start;
}

.active-facets__button {
  display: block;
  margin-right: 1.5rem;
  margin-top: 1.5rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  text-decoration: none;
}

span.active-facets__button-inner {
  color: rgb(var(--color-foreground));
  box-shadow: 0 0 0 0.1rem rgb(var(--color-foreground));
  border-radius: 2.6rem;
  font-size: 1rem;
  min-height: 0;
  min-width: 0;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
}

span.active-facets__button-inner:before,
span.active-facets__button-inner:after {
  display: none;
}

.active-facets__button-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
}

.active-facets__button-wrapper * {
  font-size: 1rem;
}

@media screen and (min-width: 990px) {
  .active-facets__button {
    margin-right: 1.5rem;
  }

  .active-facets__button-wrapper *,
  span.active-facets__button-inner {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 989px) {

  .active-facets__button,
  .active-facets__button-remove {
    margin: 0;
    padding: 1.2rem;
  }

  span.active-facets__button-inner {
    padding-bottom: 0.3rem;
    padding-top: 0.3rem;
  }

  .active-facets__button-wrapper {
    padding-top: 0;
    margin-left: 1.2rem;
  }
}

.active-facets__button:hover .active-facets__button-inner {
  box-shadow: 0 0 0 0.2rem rgb(var(--color-foreground));
}

.active-facets__button--light .active-facets__button-inner {
  box-shadow: 0 0 0 0.1rem rgba(var(--color-foreground), 0.2);
}

.active-facets__button--light:hover .active-facets__button-inner {
  box-shadow: 0 0 0 0.2rem rgba(var(--color-foreground), 0.4);
}

a.active-facets__button:focus-visible {
  outline: none;
  box-shadow: none;
}

/* outline styling for Windows High Contrast Mode */
@media (forced-colors: active) {
  a.active-facets__button:focus-visible {
    outline: transparent solid 1px;
  }
}
a.active-facets__button.focused {
  outline: none;
  box-shadow: none;
}

a.active-facets__button:focus-visible .active-facets__button-inner {
  box-shadow: 0 0 0 0.1rem rgba(var(--color-foreground), 0.2), 0 0 0 0.2rem rgb(var(--color-background)),
    0 0 0 0.4rem rgb(var(--color-foreground));
  outline: none;
}

a.active-facets__button.focused .active-facets__button-inner {
  box-shadow: 0 0 0 0.1rem rgba(var(--color-foreground), 0.2), 0 0 0 0.2rem rgb(var(--color-background)),
    0 0 0 0.4rem rgb(var(--color-foreground));
  outline: none;
}

.active-facets__button svg {
  align-self: center;
  flex-shrink: 0;
  margin-left: 0.8rem;
  margin-right: 0;
  pointer-events: none;
  width: 0.8rem;
  height: 0.8rem;
  opacity: 0.5;
}

.active-facets facet-remove:only-child {
  display: none;
}

.facets-vertical .active-facets .active-facets-vertical-filter:only-child > facet-remove {
  display: none;
}

.facets-vertical .active-facets-vertical-filter {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.facets-vertical .active-facets-vertical-filter .active-facets__button-wrapper {
  padding-top: 0;
  display: flex;
  align-items: flex-start;
}

.facets-vertical .active-facets__button {
  margin-top: 0;
}

.active-facets__button.disabled,
.mobile-facets__clear.disabled {
  pointer-events: none;
}

.mobile-facets__clear-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.mobile-facets {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(var(--color-foreground), 0.5);
  pointer-events: none;
}

.mobile-facets__disclosure {
  display: flex;
}

.mobile-facets__wrapper {
  margin-left: 0;
  display: none;
}

.mobile-facets__wrapper .disclosure-has-popup[open] > summary::before {
  height: 100vh;
  z-index: 3;
}

.mobile-facets__inner {
  background-color: rgb(var(--color-background));
  width: calc(100% - 5rem);
  margin-left: auto;
  height: 100%;
  overflow-y: auto;
  pointer-events: all;
  transition: transform var(--duration-default) ease;
  max-width: 37.5rem;
  display: flex;
  flex-direction: column;
  border-color: rgba(var(--color-foreground), var(--drawer-border-opacity));
  border-style: solid;
  border-width: 0 0 0 var(--drawer-border-width);
  filter: drop-shadow(
    var(--drawer-shadow-horizontal-offset) var(--drawer-shadow-vertical-offset) var(--drawer-shadow-blur-radius)
      rgba(var(--color-shadow), var(--drawer-shadow-opacity))
  );
}

.menu-opening .mobile-facets__inner {
  transform: translateX(0);
}

.js .disclosure-has-popup:not(.menu-opening) .mobile-facets__inner {
  transform: translateX(105vw);
}

.mobile-facets__header {
  border-bottom: 0.1rem solid rgba(var(--color-foreground), 0.08);
  padding: 1rem 2.5rem;
  text-align: center;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 2;
}

.mobile-facets__header-inner {
  flex-grow: 1;
  position: relative;
}

.mobile-facets__info {
  padding: 0 2.6rem;
}

.mobile-facets__heading {
  font-size: calc(var(--font-heading-scale) * 1.4rem);
  margin: 0;
}

.mobile-facets__count {
  color: rgba(var(--color-foreground), 0.7);
  font-size: 1.3rem;
  margin: 0;
  flex-grow: 1;
}

.mobile-facets__open-wrapper {
  display: inline-block;
}

.mobile-facets__open {
  text-align: left;
  width: 100%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  color: rgba(var(--color-link), var(--alpha-link));
}

.mobile-facets__open:hover {
  color: rgb(var(--color-link));
}

.mobile-facets__open:hover line,
.mobile-facets__open:hover circle {
  stroke: rgb(var(--color-link));
}

.mobile-facets__open-label {
  transition: text-decoration var(--duration-short) ease;
}

.mobile-facets__open:hover .mobile-facets__open-label {
  text-decoration: underline;
  text-underline-offset: 0.3rem;
}

.mobile-facets__open > * + * {
  margin-left: 1rem;
}

.mobile-facets__open svg {
  width: 2rem;
}

.mobile-facets__open line,
.mobile-facets__open circle {
  stroke: rgba(var(--color-link), var(--alpha-link));
}

.mobile-facets__close {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0.7rem;
  right: 1rem;
  width: 4.4rem;
  height: 4.4rem;
  z-index: 101;
  opacity: 0;
  transition: opacity var(--duration-short) ease;
}

.mobile-facets__close svg {
  width: 2.2rem;
}

details.menu-opening .mobile-facets__close {
  display: flex;
  opacity: 1;
}

details.menu-opening .mobile-facets__close svg {
  margin: 0;
}

.mobile-facets__close-button {
  align-items: center;
  background-color: transparent;
  font-size: 1.4rem;
  font: inherit;
  letter-spacing: inherit;
  margin-top: 1.5rem;
  padding: 1.2rem 2.6rem;
  text-decoration: none;

  display: grid;
  grid-template-columns: min-content 1fr;
  text-align: start;
}

.mobile-facets__close-button > .facets__and-helptext {
  grid-column-start: 2;
}

.mobile-facets__close-button .icon-arrow {
  transform: rotate(180deg);
  margin-right: 1rem;
}

.mobile-facets__main {
  padding: 2.7rem 0 0;
  position: relative;
  z-index: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.mobile-facets__details[open] .icon-caret {
  transform: rotate(180deg);
}

.mobile-facets__highlight {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: block;
  background-color: rgba(var(--color-foreground), 0.04);
  opacity: 0;
  visibility: hidden;
}

.mobile-facets__checkbox:checked + .mobile-facets__highlight {
  opacity: 1;
  visibility: visible;
}

.mobile-facets__summary {
  padding: 1.3rem 2.5rem;
}

.mobile-facets__summary svg {
  margin-left: auto;
}

.mobile-facets__summary > div {
  display: flex;
  align-items: center;
}

.js .mobile-facets__submenu {
  position: absolute;
  top: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 3;
  transform: translateX(100%);
  visibility: hidden;
  display: flex;
  flex-direction: column;
}

.js details[open] > .mobile-facets__submenu {
  transition: transform 0.4s cubic-bezier(0.29, 0.63, 0.44, 1), visibility 0.4s cubic-bezier(0.29, 0.63, 0.44, 1);
}

.js details[open].menu-opening > .mobile-facets__submenu {
  transform: translateX(0);
  visibility: visible;
}

.js .menu-drawer__submenu .mobile-facets__submenu {
  overflow-y: auto;
}

.js .mobile-facets .submenu-open {
  visibility: hidden; /* hide menus from screen readers when hidden by submenu */
}

.mobile-facets__item {
  position: relative;
}

input.mobile-facets__checkbox {
  border: 0;
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  left: 2.1rem;
  top: 1.2rem;
  z-index: 0;
  appearance: none;
  -webkit-appearance: none;
}

.mobile-facets__label {
  padding: 1.5rem 2rem 1.5rem 2.5rem;
  width: 100%;
  transition: background-color 0.2s ease;
  word-break: break-word;
  display: flex;
}

.mobile-facets__label > svg {
  background-color: rgb(var(--color-background));
  position: relative;
  z-index: 2;
  margin-right: 1.2rem;
  flex-shrink: 0;
}

.mobile-facets__label .icon-checkmark {
  position: absolute;
  top: 1.9rem;
  left: 2.8rem;
  visibility: hidden;
}

.mobile-facets__label > input[type='checkbox']:checked ~ .icon-checkmark {
  visibility: visible;
}

.mobile-facets__arrow,
.mobile-facets__summary .icon-caret {
  margin-left: auto;
  display: block;
}

.mobile-facets__footer {
  border-top: 0.1rem solid rgba(var(--color-foreground), 0.08);
  padding: 2rem;
  bottom: 0;
  position: sticky;
  display: flex;
  z-index: 2;
  margin-top: auto;
  background-color: rgb(var(--color-background));
  background: var(--gradient-background);
}

.mobile-facets__footer > * + * {
  margin-left: 1rem;
}

.mobile-facets__footer > * {
  width: 50%;
}

.mobile-facets__sort {
  display: flex;
  justify-content: space-between;
}

.mobile-facets__sort label {
  flex-shrink: 0;
}

.mobile-facets__sort .select {
  width: auto;
}

.mobile-facets__sort .select .icon-caret {
  right: 0;
}

.mobile-facets__sort .select__select {
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
  filter: none;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 1.5rem;
}

.product-count {
  align-self: center;
  position: relative;
  text-align: right;
}

.product-count__text {
  font-size: 1.4rem;
  line-height: calc(1 + 0.5 / var(--font-body-scale));
  margin: 0;
}

#ProductCountDesktop.loading,
#ProductCount.loading {
  visibility: hidden;
}

.product-count .loading__spinner,
.product-count-vertical .loading__spinner {
  position: absolute;
  padding-top: 0.6rem;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1.8rem;
}
.facets__display-vertical {
  padding: 1.8rem 0 0;
}

.facets-vertical .active-facets facet-remove:first-of-type {
  margin-top: 2rem;
}

@media screen and (min-width: 750px) {
  .facets-vertical {
    display: flex;
  }

  .facets-wrap-vertical {
    border: none;
    padding-left: 0;
  }

  .facets__form-vertical {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .facets__disclosure-vertical {
    border-top: 0.1rem solid rgba(var(--color-foreground), 0.1);
    margin-right: 0;
  }

  .facets__heading--vertical {
    margin: 0 0 1.5rem 0;
    font-size: 1.5rem;
  }

  .facets__header-vertical {
    padding: 1.5rem 2rem 1.5rem 0;
    font-size: 1.4rem;
  }

  .facets__display-vertical {
    padding: 1.5rem 1.5rem 0.5rem 0;
  }

  .facets-vertical .facets-wrapper {
    padding-right: 3rem;
    width: calc(22.258% + 3rem);
  }

  .facets-vertical .facets-wrapper--no-filters {
    display: none;
  }

  .facets-vertical .product-grid-container {
    width: 100%;
    padding-bottom: 3rem;
  }

  .facets-vertical-form {
    display: flex;
    justify-content: flex-end;
  }

  .product-count-vertical {
    margin-left: 3.5rem;
    position: relative;
  }

  .facets-vertical .active-facets__button-wrapper {
    margin-bottom: 2rem;
  }

  .facets-vertical .facets__price {
    padding: 0.5rem 0.5rem 0.5rem 0;
  }

  .facets-vertical .facets__price .field:last-of-type {
    margin-left: 1rem;
  }

  .facets-vertical .active-facets__button {
    margin-bottom: 1.5rem;
  }

  .facets-vertical .facet-checkbox input[type='checkbox'] {
    z-index: 0;
  }

  .facets-vertical .active-facets facet-remove:first-of-type {
    margin-top: 1.8rem;
  }
  .facets-vertical .active-facets facet-remove:last-of-type {
    margin-bottom: 1rem;
  }

  .facets__disclosure-vertical[open] .facets__summary .icon-caret {
    transform: rotate(180deg);
  }

  .facets-container-drawer {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    column-gap: 0;
  }

  .facets-container-drawer .mobile-facets__wrapper {
    margin-right: 2rem;
    flex-grow: 1;
  }

  .facets-container-drawer .product-count {
    margin: 0 0 0.5rem 3.5rem;
  }

  .facets-container-drawer .facets-pill {
    width: 100%;
  }

  .facets-container-drawer .facets__form {
    display: block;
  }
}

@media screen and (min-width: 750px) and (max-width: 989px) {
  .facets-vertical .active-facets__button {
    padding: 1rem;
    margin-bottom: 0;
    margin-left: -0.5rem;
  }

  .facets-vertical .active-facets__button-remove {
    padding: 0 1rem 1rem;
  }
}

/*--------Custom----------*/
.facets-container .facets__heading {
    font-family: var(--font-family);
    font-size: 1.4rem;
    line-height: 1.4rem;
    font-weight: 700;
    color: rgb(var(--color-foreground));
    margin: 0 0 1.5rem;
}

.facets-container .facets__disclosure-vertical {
  border: none;
}

.facets-container .facets__disclosure-vertical .facets__summary,
.facets-container .active-facets-vertical-filter {
  position: relative;
  border-bottom: 0.1rem solid rgba(var(--color-shadow));
}

.facets-container .facets__disclosure-vertical .facets__summary.focus-offset:focus-visible {
  outline: none;
  outline-offset: unset;
  box-shadow: none;
}

.facets-container .active-facets-vertical-filter::before,
.facets-container .facets__disclosure-vertical .facets__summary::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -0.2rem;
  width: 4rem;
  height: 0.3rem;
  background: rgba(var(--color-button),1);
}

.facets-container .active-facets-vertical-filter {
  /* margin-bottom: 1.8rem; */
}

.active-facets__button-remove {
  display: none;
}

.facet-checkbox > svg {
  display: none;
}

.facets-layout-list--text input[type='checkbox']:checked {
  background: rgba(var(--color-button),1) url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.4803 2.07616L3.40016 5.47628L0.52002 3.07616L1.4803 1.92383L3.40016 3.52371L6.52002 0.923828L7.4803 2.07616Z' fill='white'/%3E%3C/svg%3E%0A") no-repeat center center;
  border-color: rgba(var(--color-button),1);
}

.facets-wrap.facets-wrap-vertical {
  margin: 0;
  padding: 0;
  border: none;
}

.facets-container .facets__summary  svg.icon-caret {
  display: none;
}

.facets-container details .facets__summary::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 1rem;
  height: 1rem;
  margin: auto;
  background: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 6V10H6V6H10V4H6V0H4V4H0V6H4Z' fill='%23818FA0'/%3E%3C/svg%3E%0A") no-repeat center center;
  transition: ease-in-out 0.1s;
}

.facets-container details[open] .facets__summary::after {
  background: url("data:image/svg+xml,%3Csvg width='10' height='2' viewBox='0 0 10 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='10' height='2' fill='%23818FA0'/%3E%3C/svg%3E%0A") no-repeat center center;
}

@media (hover: hover) {
  .facets-container details .facets__summary:hover::after {
    background: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 4V0H4V4H0V6H4V10H6V6H10V4H6Z' fill='%23FF6720'/%3E%3C/svg%3E%0A") no-repeat center center;
  }

  .facets-container details[open] .facets__summary:hover::after {
    background: url("data:image/svg+xml,%3Csvg width='10' height='2' viewBox='0 0 10 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10 2H0V0H10V2Z' fill='%23FF6720'/%3E%3C/svg%3E%0A") no-repeat center center;
  }

  .active-facets .active-facets__button .active-facets__button-inner:hover {
    background: rgba(var(--color-foreground), 1);
    color: rgba(var(--color-background), 1);
  }
  
  .active-facets .active-facets__button .active-facets__button-inner:hover svg {
    opacity: 1;
  }

  .facet-checkbox:hover {
    color: rgba(var(--color-button),1);
    text-decoration: none;
  }

}

.facets-container .product-count {
  display: none;
}

.facets-container .facets__summary .facets__summary-label {
  font-family: var(--font-family);
  font-size: 1.4rem;
  line-height: 1.4rem;
  text-transform: uppercase;
  font-weight: 700;
  color: rgb(var(--color-foreground));
}

.active-facets .active-facets__button {
  padding: 0;
  margin: 0 1rem 1rem 0;
}

.active-facets .active-facets__button .active-facets__button-inner {
  font-family: var(--font-family);
  font-size: 1.2rem;
  line-height: 1.68rem;
  border: none;
  background: rgba(var(--color-foreground), 0.05);
  border-radius: 0.6rem;
  box-shadow: none;
  padding: 0.561rem 0.95rem;
  font-style: normal;
  font-weight: 400;
  text-transform: none;
  transition: ease-in-out 0.3s;
}

.active-facets a.active-facets__button:focus-visible .active-facets__button-inner {
  box-shadow: none;
  outline: none;
}

.facets .product-count-vertical {
  display: none;
}

.facet-filters .select2-search--dropdown+.select2-results {
  max-height: inherit;
}

.facet-filters .select2-container--default .select2-results>.select2-results__options {
  max-height: inherit;
}

.facet-filters .facet-filters__field .select {
  min-width: 22.8rem;
}

.facet-filters .facet-filters__label {
  font-family: var(--font-family);
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.44rem;
  color: rgba(var(--color-foreground),1);
  text-transform: none;
  margin-right: 1.5rem;
}


.product-grid-container .product-grid {
  margin: 0;
}

.mobile-facets__wrapper + .active-facets-mobile {
  display: none;
}

.facet-filters .select2-container .select2-selection--single .select2-selection__rendered {
  font-size: 1.2rem;
}


@media screen and (max-width: 1023px) {
  #main-collection-filters,#main-search-filters {
    display: none;
  }

  .facets-vertical {
    display: block;
  }

  .active-facets .active-facets__button .active-facets__button-inner {
    font-size: 1.4rem;
    line-height: 1.96rem;
    padding: 0.521rem 0.925rem;
  }

  .facets-container {
    padding-top: 1.3rem;
  }

  .facets-container .facets__heading {
    margin-bottom: 1.6rem;
  }

  .facets.facets-vertical-sort {
    display: none;
  }

  .facets-vertical .facets-wrapper {
    width: 100%;
    padding: 0;
  }

}